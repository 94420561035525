import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/usr/src/app/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "become-an-integration-partner-and-get-your-app-certified"
    }}>{`Become an Integration Partner and get your app certified`}</h1>
    <p>{`Thank you for your interest in integrating to Planday and using our APIs to build powerful apps that leverage the power of Planday's workforce collaboration platform.`}</p>
    <p>{`To become an Integration Partner you will have to follow steps below and fulfill the listed requirements. Once certified, you will be listed on the `}<a parentName="p" {...{
        "href": "https://www.planday.com/"
      }}>{`planday.com`}</a>{` webpage, on the integrations in-product page and you will get an opportunity for a lead-sharing agreement and joint marketing campaigns.`}</p>
    <p>{`Here are the steps and requirements for getting certified.`}</p>
    <h3 {...{
      "id": "1-build-your-integration-with-planday"
    }}>{`1) Build your integration with Planday`}</h3>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://developer.planday.com/gettingstarted/overview"
      }}>{`Getting Started`}</a>{` section will get you up and running with all the info you need to work with our API, it also contains a link to a form based on which we will create a demo portal for you.`}<br />{`
In the `}<a parentName="p" {...{
        "href": "https://developer.planday.com/guides/hr-guide"
      }}>{`Guides`}</a>{` menu, you can find helpful how-to articles for the most common use cases for integrations with Planday.`}<br />{`
We encourage you to also browse through the `}<a parentName="p" {...{
        "href": "https://help.planday.com/en/"
      }}>{`Planday Help Center`}</a>{` to get to know some of the basic features and functionality of Planday.`}<br /></p>
    <p>{`On your demo portal, you will have to create an API app used in the OAuth2 flow. We recommend creating one test app and one production app. Make sure the production app is named after your product and does not contain words such as "prod".`}</p>
    <p>{`Reach out to our `}<a parentName="p" {...{
        "href": "https://openapi.planday.com/gettingstarted/api-support"
      }}>{`API Support`}</a>{` in case of any questions.`}</p>
    <h3 {...{
      "id": "2-run-a-pilot-test-with-planday-customers"
    }}>{`2) Run a pilot test with Planday customers`}</h3>
    <p>{`Once you have completed your implementation, you should validate the integration in a live environment with a few customers. This is also an opportunity to resolve any problems before a full release.`}<br />{`
While we prefer at least 2 customers to have tested the integration, 1 customer test can be accepted in some cases.`}</p>
    <h3 {...{
      "id": "3-demo-your-integration-for-us"
    }}>{`3) Demo your integration for us`}</h3>
    <p>{`To kick off the review process, fill out the `}<a parentName="p" {...{
        "href": "https://forms.gle/rxFxAr6qV44X19aD7"
      }}>{`Partner Certification form`}</a>{`.`}<br />{`
Once we receive your submission, we will contact you regarding a demo booking. You will be asked to introduce your product, show the integration setup process, and present the integration's functionality.`}<br />{`
Please keep in mind that we may request that you implement adjustments to the integration, if necessary.`}</p>
    <h3 {...{
      "id": "4-publish-setup-documentation"
    }}>{`4) Publish setup documentation`}</h3>
    <p>{`To assist our customers with an easy integration setup, you will have to create support documentation. The support documentation needs to be publicly accessible, so our internal teams can direct our customers to it. Please make sure the documentation is continuously updated and in line with any adjustments you might introduce to the integration.`}</p>
    <p>{`We recommend that your support documentation includes:`}</p>
    <ul>
      <li parentName="ul">{`Dataflow diagram`}
        <ul parentName="li">
          <li parentName="ul">{`Make a diagram that clearly states which direction the data flows between the systems and what data is supported. Make it easy for customers to get a high-level understanding of the integration.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Setup guide`}
        <ul parentName="li">
          <li parentName="ul">{`Clearly guide the user through the steps required to set up the integration, and show where the integration can be set up, how to authenticate and configure the integration.`}</li>
          <li parentName="ul">{`Use screenshots, so that customers can easily see where and how to navigate through the setup.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`FAQ`}
        <ul parentName="li">
          <li parentName="ul">{`Based on your pilot tests, you might have some valuable insights about the integration that will help other customers set up or troubleshoot the integration.`}</li>
          <li parentName="ul">{`Include any known limitations or potential issues customers might encounter during setup.`}</li>
        </ul>
      </li>
    </ul>
    <h3 {...{
      "id": "5-provide-integration-listing-assets"
    }}>{`5) Provide integration listing assets`}</h3>
    <p>{`After the demo session, we will follow up with a list of materials needed from you in order for us to publish your integration on our website and in Portal Settings. Note that texts will have to be provided in languages of all markets where your integration will be available.`}<br />{`
Once approved, you will receive an email confirming that your app is certified.`}</p>
    <h3 {...{
      "id": "6-promote-your-integration"
    }}>{`6) Promote your integration`}</h3>
    <p>{`Congrats on getting your integration certified! Now go and create some buzz about it on your favorite channels!`}<br />{`
Reach out to your contact person in Planday if you'd like to coordinate a joint marketing campaign.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      